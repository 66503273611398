import * as Tone from 'tone'
import axios from 'axios'
import * as LogHelper from './LogHelper'
import { SynthInstruments, DrumsInstruments } from './Instruments'

let isToneSupported = true
try {
  isToneSupported = Tone.supported
  if (!process.browser) {
    // Make sure the Next renders it correctly
    isToneSupported = true
  }
} catch (e) {
  isToneSupported = false
}

// Our midi files have 480 ticks per quarter resolution. Tone.js has 120. 
// Therefore we need to convert midi ticks to Tone.js ticks.
const MIDI_TICKS = 480
const PPQ = 240
const MIDI_TO_PPQ = PPQ / MIDI_TICKS
const SAMPLE_RATE = 44100

const keyValueList = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B']

class SoundHelper {
  context = isToneSupported ? Tone.context : null
  isTransportPlaying = false

  isDrumsLoaded = false
  isSynthLoaded = false
  lastSynthId = -1
  lastDrumsId = -1

  transportBpm = 120
  synthFaderVolume = 0
  drumsFaderVolume = 0

  oneShotPlayer = null
  oneShotTimeout = null

  // Single synth object contains .sampler, .config and .buffer. It can be accessed by synthId.
  allSynthObjects = {}
  synthSampler = null
  synthBuffer = null
  synthConfig = null

  // Single drums object contains .player, .config and .buffer. It can be accessed by drumsId.
  allDrumsObjects = {}
  drumsPlayer = null
  drumsBuffer = null
  drumsConfig = null

  synthPartList = []
  drumsPartList = []
  offlineSynthSampler = null

  // This is not a typo. Should be player, but player sucks in Tone.Offline. Some bug there.
  offlineDrumsSampler = null
  // This is the temporary solution to fix the issue :(
  drumsBugFixDict = {
    'snare': 'C4',
    'kick': 'C5',
    'hihat': 'C6',
    'clap': 'C7',
    'hihat_open': 'C8',
  }

  init = (onSoundsLoadCallback, onChordCallback) => {
    this.onSoundsLoadCallback = onSoundsLoadCallback
    this.onChordCallback = onChordCallback

    if (!isToneSupported) {
      return
    }

    Tone.Transport.PPQ = PPQ

    this.oneShotPlayerInput = new Tone.Limiter(0)
    this.oneShotPlayerVolume = new Tone.Volume(0)
    this.oneShotPlayerInput.connect(this.oneShotPlayerVolume)
    this.oneShotPlayerVolume.toDestination()

    this.soundNode = new Tone.Volume(5)

    const synthChain = this.createSoundChain(this.soundNode, 0.2, this.triggerSynth)
    this.synthInputLimiter = synthChain.input
    this.synthLevel = synthChain.level
    this.synthFader = synthChain.fader
    this.synthPart = synthChain.part

    const drumsChain = this.createSoundChain(this.soundNode, 0.0, this.triggerDrums)
    this.drumsInputLimiter = drumsChain.input
    this.drumsLevel = drumsChain.level
    this.drumsFader = drumsChain.fader
    this.drumsPart = drumsChain.part

    this.soundNode.toDestination()

    this.chordCallbackPart = new Tone.Part(this.triggerChordCallback)
    this.chordCallbackPart.start('0:0')
    this.chordCallbackPart.loop = true
    this.chordCallbackPart.loopEnd = '4m'
  }

  createSoundChain = (output, reverbValue, triggerCallback) => {
    let soundChain = {}
    soundChain.reverb = this.createReverb(reverbValue)
    soundChain.input = new Tone.Limiter(0)
    soundChain.level = new Tone.Volume(0)
    soundChain.fader = new Tone.Volume(0)
    soundChain.input.connect(soundChain.reverb)
    soundChain.reverb.connect(soundChain.level)
    soundChain.level.connect(soundChain.fader)
    soundChain.fader.connect(output)

    soundChain.part = new Tone.Part(triggerCallback)
    soundChain.part.start('0:0')
    soundChain.part.loop = true
    soundChain.part.loopEnd = '4m'

    return soundChain
  }

  createReverb = (value) => {
    let reverb = new Tone.Reverb()
    reverb.wet.value = value
    reverb.generate()
    return reverb
  }

  parseSampleBuffers = (config, buffer, drumsBugFix = false) => {
    let sampleBuffers = {}
    for (let sample of config.samples) {
      let start = parseFloat(sample.offset)
      let end = start + parseFloat(sample.duration)
      if (!drumsBugFix) {
        sampleBuffers[sample.root] = buffer.slice(start, end)
      } else {
        sampleBuffers[this.drumsBugFixDict[sample.root]] = buffer.slice(start, end)
      }
    }
    return sampleBuffers
  }

  createSynth = (config, buffer, drumsBugFix = false) => {
    const sampleBuffers = this.parseSampleBuffers(config, buffer, drumsBugFix)
    let sampler = new Tone.Sampler(sampleBuffers, {
      'attack': config.envelope.attack,
      'release': config.envelope.release,
    })
    return sampler
  }

  createPlayer = (config, buffer) => {
    const sampleBuffers = this.parseSampleBuffers(config, buffer)
    let player = new Tone.Players(sampleBuffers, {
      'attack': config.envelope.attack,
      'release': config.envelope.release,
    })
    return player
  }

  applyLevelInputConfig = (config, level, input) => {
    level.volume.value = config.volume
    input.threshold.value = config.limiter.threshold
  }

  midiToNoteName = (midi) => {
    let noteName = keyValueList[midi % 12] + (parseInt(midi / 12)).toString()
    return noteName
  }

  isAllSoundsLoaded = () => {
    return this.isSynthLoaded && this.isDrumsLoaded
  }

  onSynthLoadCallback = () => {
    this.isSynthLoaded = true
    this.isAllSoundsLoaded() && this.onSoundsLoadCallback()
  }

  onDrumsLoadCallback = () => {
    this.isDrumsLoaded = true
    this.isAllSoundsLoaded() && this.onSoundsLoadCallback()
  }

  playSingleChord = (chord) => {
    if (!this.isSynthLoaded)
      return
    chord.notes.forEach(note => {
      this.synthSampler.triggerAttackRelease(this.midiToNoteName(note.midi - 24), '0:2')
    })
  }

  setBpm = (bpm) => {
    Tone.Transport.bpm.value = bpm
    this.transportBpm = bpm
  }

  setArpeggio = (arpeggio, chords) => {
    this.synthPartList = []
    this.synthPart.clear()
    this.chordCallbackPart.clear()
    try {
      this.synthSampler.releaseAll()
    } catch (err) {
      LogHelper.logSentry('synthSampler can\'t releaseAll')
    }

    chords.forEach((chord, index) => {
      this.chordCallbackPart.add(index.toString() + ':0', index)
      Object.keys(arpeggio).forEach(key => {
        const intKey = parseInt(key)
        arpeggio[key].forEach(setup => {
          const newMidi = this.mapMidiToChord(intKey, chord) - 24
          // `setup` example -> ['noteOff', 174, 58]
          const noteName = this.midiToNoteName(newMidi)
          const eventType = setup[0]
          const timingTicks = Math.floor(PPQ * 4 * index + setup[1] * MIDI_TO_PPQ)
          const velocity = setup[2] / 127.0
          const args = [noteName, eventType, velocity]
          // Sweet fix, when the `noteOff` occurs the same time as `noteOn`
          if (eventType === 'noteOn') {
            this.synthPart.add(`${timingTicks}i`, args)
            this.synthPartList.push([`${timingTicks}i`, args])
          } else if (eventType === 'noteOff') {
            this.synthPart.add(`${timingTicks - 1}i`, args)
            this.synthPartList.push([`${timingTicks - 1}i`, args])
          }
        })
      })
    })
  }

  setDrums = (drums) => {
    this.drumsPartList = []
    this.drumsPart.clear()
    Object.keys(drums).forEach(drumName => {
      drums[drumName].forEach(timing => {
        const timingTicks = timing * MIDI_TO_PPQ
        this.drumsPart.add(`${timingTicks}i`, drumName)
        this.drumsPartList.push([`${timingTicks}i`, drumName])
      })
    })
  }

  setVolume = (synthVolume, drumsVolume) => {
    this.synthFaderVolume = synthVolume === 0 ? -100 : synthVolume / 2 - 55
    this.synthFader.volume.value = this.synthFaderVolume

    this.drumsFaderVolume = drumsVolume === 0 ? -100 : drumsVolume / 2 - 55
    this.drumsFader.volume.value = this.drumsFaderVolume
  }

  setSynthInstrument = (synthId, forceMp3 = false) => {
    if (!isToneSupported) {
      return
    }
    if (synthId === this.lastSynthId) {
      return
    }
    this.lastSynthId = synthId

    try {
      this.synthSampler.releaseAll()
    } catch (err) {
      LogHelper.logSentry('synthSampler can\'t releaseAll')
    }

    const synthInstrument = SynthInstruments[synthId]

    if (synthId in this.allSynthObjects) {
      // Already loaded. So let's just use the existing sampler.
      this.synthSampler = this.allSynthObjects[synthId].sampler
      this.synthBuffer = this.allSynthObjects[synthId].buffer
      this.synthConfig = this.allSynthObjects[synthId].config
      this.applyLevelInputConfig(this.synthConfig, this.synthLevel, this.synthInputLimiter)
      return
    }

    this.isSynthLoaded = false

    let synthPath = `/static/sounds/new_synths/${synthInstrument.folder}/instrument.ogg`
    let mapPath = `/static/sounds/new_synths/${synthInstrument.folder}/instrument.json`
    if (!Tone.Buffer.supportsType(synthPath) || forceMp3) {
      synthPath = `/static/sounds/new_synths/${synthInstrument.folder}/instrument_compressed.mp3`
    }

    LogHelper.logSentry('Loading Tone Buffer For Synth Instrument')

    this.synthBuffer = new Tone.Buffer(synthPath, async () => {
      let response = await axios.get(mapPath)
      this.synthConfig = response.data
      this.synthSampler = this.createSynth(this.synthConfig, this.synthBuffer)
      this.synthSampler.connect(this.synthInputLimiter)

      this.allSynthObjects[synthId] = {
        sampler: this.synthSampler,
        buffer: this.synthBuffer,
        config: this.synthConfig,
      }
      this.applyLevelInputConfig(this.synthConfig, this.synthLevel, this.synthInputLimiter)
      this.onSynthLoadCallback()

    }, err => {
      if (!forceMp3) {
        LogHelper.logSentry('Mp3 is not forced. Forcing mp3 and let\'s try setSynthInstrument again.')
        this.setSynthInstrument(synthId, true)
      } else {
        LogHelper.err(err)
      }
    })

  }

  setDrumsInstrument = (drumsId, forceMp3 = false) => {
    if (!isToneSupported) {
      return
    }
    if (drumsId === this.lastDrumsId) {
      return
    }
    this.lastDrumsId = drumsId

    const drumsInstrument = DrumsInstruments[drumsId]

    if (drumsId in this.allDrumsObjects) {
      // Already loaded. So let's just use the existing player.
      this.drumsPlayer = this.allDrumsObjects[drumsId].player
      this.drumsBuffer = this.allDrumsObjects[drumsId].buffer
      this.drumsConfig = this.allDrumsObjects[drumsId].config
      this.applyLevelInputConfig(this.drumsConfig, this.drumsLevel, this.drumsInputLimiter)
      return
    }

    this.isDrumsLoaded = false

    let drumsPath = `/static/sounds/new_drums/${drumsInstrument.folder}/instrument.ogg`
    let mapPath = `/static/sounds/new_drums/${drumsInstrument.folder}/instrument.json`
    if (!Tone.Buffer.supportsType(drumsPath) || forceMp3) {
      drumsPath = `/static/sounds/new_drums/${drumsInstrument.folder}/instrument.mp3`
    }

    LogHelper.logSentry('Loading Tone Buffer For Drums Instrument')

    this.drumsBuffer = new Tone.Buffer(drumsPath, async () => {
      let response = await axios.get(mapPath)
      this.drumsConfig = response.data
      this.drumsPlayer = this.createPlayer(this.drumsConfig, this.drumsBuffer)
      this.drumsPlayer.connect(this.drumsInputLimiter)

      this.allDrumsObjects[drumsId] = {
        player: this.drumsPlayer,
        buffer: this.drumsBuffer,
        config: this.drumsConfig,
      }
      this.applyLevelInputConfig(this.drumsConfig, this.drumsLevel, this.drumsInputLimiter)
      this.onDrumsLoadCallback()

    }, err => {
      if (!forceMp3) {
        LogHelper.logSentry('Mp3 is not forced. Forcing mp3 and let\'s try setDrumsInstrument again.')
        this.setDrumsInstrument(drumsId, true)
      } else {
        LogHelper.err(err)
      }
    })

  }

  triggerSynthSampler = (sampler, time, args) => {
    const noteName = args[0]
    const eventType = args[1]
    const velocity = args[2]

    if (eventType === 'noteOn') {
      sampler.triggerAttack(noteName, time, velocity)
    } else if (eventType === 'noteOff') {
      sampler.triggerRelease(noteName, time, velocity)
    }
  }

  triggerSynth = (time, args) => {
    if (!this.isSynthLoaded || !this.isTransportPlaying)
      return
    this.triggerSynthSampler(this.synthSampler, time, args)
  }

  triggerOfflineSynth = (time, args) => {
    if (!this.isSynthLoaded)
      return
    this.triggerSynthSampler(this.offlineSynthSampler, time, args)
  }

  triggerDrumsPlayer = (player, time, drumName) => {
    player.player(drumName).retrigger = true
    player.player(drumName).start(time)
  }

  triggerDrums = (time, drumName) => {
    if (!this.isDrumsLoaded)
      return
    this.triggerDrumsPlayer(this.drumsPlayer, time, drumName)
  }

  // Bug fix of this whole strange thing with offlineDrumsPlayer..
  triggerOfflineDrums = (time, drumName) => {
    if (!this.isDrumsLoaded)
      return
    this.offlineDrumsSampler.triggerAttackRelease(this.drumsBugFixDict[drumName], '8n', time)
  }

  triggerChordCallback = (time, chordIndex) => {
    Tone.Draw.schedule(() => {
      this.onChordCallback(chordIndex)
    }, time)
  }

  // StartAudioContext fix, so the Tone sound is actually started on iOS
  start = () => {
    if (isToneSupported)
      Tone.start()
  }

  play = () => {
    Tone.Transport.start()
    this.isTransportPlaying = true
  }

  stop = () => {
    Tone.Transport.stop()
    this.isTransportPlaying = false
    if (this.isSynthLoaded)
      this.synthSampler.releaseAll()
  }

  playOneShot = async (configUrl, soundUrl, onLoadCallback, onFinishCallback) => {
    this.stopOneShot()
    const response = await axios.get(configUrl)
    const oneShotConfig = response.data

    this.oneShotPlayerInput.threshold.value = oneShotConfig.limiter.threshold
    this.oneShotPlayerVolume.volume.value = oneShotConfig.volume

    this.oneShotPlayer = new Tone.Player()
    this.oneShotPlayer.connect(this.oneShotPlayerInput)
    this.oneShotPlayer.unsync()
    this.oneShotPlayer.load(soundUrl, () => {
      onLoadCallback()
      this.oneShotPlayer.start()
      this.oneShotTimeout = setTimeout(onFinishCallback, this.oneShotPlayer.buffer.duration * 1000)
    })
  }

  stopOneShot = () => {
    try {
      clearTimeout(this.oneShotTimeout)
      this.oneShotPlayer.stop()
      this.oneShotPlayer.dispose()
    } catch (err) {
      LogHelper.logSentry('Can\'t stopOneShot.')
    }
  }

  onPressPianoNote = (lowMidi) => {
    if (!this.isSynthLoaded)
      return
    let midi = lowMidi + 36
    this.synthSampler.triggerAttack(this.midiToNoteName(midi))
  }

  onReleasePianoNote = (lowMidi) => {
    if (!this.isSynthLoaded)
      return
    let midi = lowMidi + 36
    this.synthSampler.triggerRelease(this.midiToNoteName(midi))
  }

  exportBuffer = (exportBufferCallback) => {
    Tone.Offline(({ transport }) => {
      transport.PPQ = PPQ
      transport.bpm.value = this.transportBpm

      const output = new Tone.Volume(5)
      output.toDestination()

      // Synth
      const synthChain = this.createSoundChain(output, 0.2, this.triggerOfflineSynth)
      synthChain.fader.volume.value = this.synthFaderVolume
      synthChain.part.loop = false

      this.offlineSynthSampler = this.createSynth(this.synthConfig, this.synthBuffer)
      this.offlineSynthSampler.connect(synthChain.input)
      this.applyLevelInputConfig(this.synthConfig, synthChain.level, synthChain.input)
      this.synthPartList.forEach(elem => synthChain.part.add(elem[0], elem[1]))

      // Drums 
      const drumsChain = this.createSoundChain(output, 0, this.triggerOfflineDrums)
      drumsChain.fader.volume.value = this.drumsFaderVolume
      drumsChain.part.loop = false

      // This is not a mistake. Players have strange bug in Tone.js.
      this.offlineDrumsSampler = this.createSynth(this.drumsConfig, this.drumsBuffer, true)
      this.offlineDrumsSampler.connect(drumsChain.input)
      this.applyLevelInputConfig(this.drumsConfig, drumsChain.level, drumsChain.input)
      this.drumsPartList.forEach(elem => { drumsChain.part.add(elem[0], elem[1]) })

      transport.start()
    }, new Tone.Time('5m'), 2, SAMPLE_RATE).then((buffer) => {
      exportBufferCallback(buffer)
    })
  }

  connectMediaStreamDest = (mediaStreamDest) => {
    Tone.connect(this.soundNode, mediaStreamDest)
  }

  mapMidiToChord = (midi, chord) => {
    if (chord.notes.length === 3) {
      switch (midi) {
        case 60:
          return chord.notes[0].midi - 12
        case 64:
          return chord.notes[1].midi - 12
        case 67:
          return chord.notes[2].midi - 12
        case 72:
          return chord.notes[0].midi
        case 76:
          return chord.notes[1].midi
        case 79:
          return chord.notes[2].midi
        case 84:
          return chord.notes[0].midi + 12
      }
    } else if (chord.notes.length === 4) {
      switch (midi) {
        case 60:
          return chord.notes[0].midi - 12
        case 64:
          return chord.notes[1].midi - 12
        case 67:
          return chord.notes[2].midi - 12
        case 72:
          return chord.notes[3].midi
        case 76:
          return chord.notes[1].midi
        case 79:
          return chord.notes[2].midi
        case 84:
          return chord.notes[3].midi
      }
    } else {
      return -1
    }
  }

}

export { isToneSupported, SoundHelper }