
export const SYNTH_TYPE = 'synth'
export const DRUMS_TYPE = 'drums'
export const INSTRUMENT_TYPES = { [SYNTH_TYPE]: 'Synth', [DRUMS_TYPE]: 'Drums' }

export const SynthInstruments = {
  '0': {
    title: '#ChordChord Piano',
    isPremium: false,
    folder: 'piano',
  },
  '1': {
    title: 'Analog Synth',
    isPremium: false,
    folder: 'analog',
  },
  '2': {
    title: 'Pulse Synth',
    isPremium: true,
    folder: 'pulse',
  },
  '3': {
    title: 'Retro Synth',
    isPremium: true,
    folder: 'retro',
  },
  '4': {
    title: 'Worm Synth',
    isPremium: true,
    folder: 'worm',
  },
  '5': {
    title: 'Square Synth',
    isPremium: true,
    folder: 'square',
  },
  '6': {
    title: 'FM Piano',
    isPremium: true,
    folder: 'fmpiano',
  },
  '7': {
    title: 'Fuzzy Synth',
    isPremium: false,
    folder: 'fuzzy',
  },
  '8': {
    title: 'Tines Synth',
    isPremium: true,
    folder: 'tines',
  },
  '9': {
    title: 'Dreamy E-Piano',
    isPremium: true,
    folder: 'dreamy_e_piano',
  },
  '10': {
    title: 'Detuned Pad',
    isPremium: false,
    folder: 'detuned_pad',
  },
  '11': {
    title: 'Dirty Lead',
    isPremium: false,
    folder: 'dirty_lead',
  },
  '12': {
    title: 'Gorgeous Pad',
    isPremium: true,
    folder: 'gorgeous_pad',
  },
  '13': {
    title: 'Keystone',
    isPremium: false,
    folder: 'keystone',
  },
  '14': {
    title: 'Low Range Piano',
    isPremium: true,
    folder: 'low_range_piano',
  },
  '15': {
    title: 'Old Grand Piano',
    isPremium: true,
    folder: 'old_grand_piano',
  },
  '16': {
    title: 'Old Pad',
    isPremium: false,
    folder: 'old_pad',
  },
  '17': {
    title: 'Pop Lead',
    isPremium: true,
    folder: 'pop_lead',
  },
  '18': {
    title: 'Retro Pluck',
    isPremium: false,
    folder: 'retro_pluck',
  },
  // '19': {
  //   title: 'Angel Pad',
  //   isPremium: false,
  //   folder: 'angel_pad',
  // },
  // '20': {
  //   title: 'Breakthrough Lead',
  //   isPremium: false,
  //   folder: 'breakthrough_lead',
  // },
  // '21': {
  //   title: 'Classic E-Piano',
  //   isPremium: false,
  //   folder: 'classic_e_piano',
  // },
  // '22': {
  //   title: 'Glass Pluck',
  //   isPremium: false,
  //   folder: 'glass_pluck',
  // },
  // '23': {
  //   title: 'Heaven Piano',
  //   isPremium: false,
  //   folder: 'heaven_piano',
  // },
  // '24': {
  //   title: 'Live Organ',
  //   isPremium: false,
  //   folder: 'live_organ',
  // },
  // '25': {
  //   title: 'NY Lights',
  //   isPremium: false,
  //   folder: 'ny_lights_pad',
  // },
  // '26': {
  //   title: 'Power Lead',
  //   isPremium: false,
  //   folder: 'power_lead',
  // },
  // '27': {
  //   title: 'Risen Pluck',
  //   isPremium: false,
  //   folder: 'risen_pluck',
  // },
  // '28': {
  //   title: 'Unique Celesta',
  //   isPremium: false,
  //   folder: 'unique_celesta',
  // },
  // '29': {
  //   title: 'Sweet Keyboard',
  //   isPremium: false,
  //   folder: 'sweet_keyboard',
  // },
  // '30': {
  //   title: 'Big Choir',
  //   isPremium: false,
  //   folder: 'big_choir',
  // },
  // '31': {
  //   title: 'Big Flute',
  //   isPremium: false,
  //   folder: 'big_flute',
  // },
  // '32': {
  //   title: 'Dance Piano',
  //   isPremium: false,
  //   folder: 'dance_piano',
  // },
  // '33': {
  //   title: 'Dark Cellos',
  //   isPremium: false,
  //   folder: 'dark_cellos',
  // },
  // '34': {
  //   title: 'Key Moon',
  //   isPremium: false,
  //   folder: 'key_moon',
  // },
  // '35': {
  //   title: 'Lofi Synth',
  //   isPremium: false,
  //   folder: 'lofi_synth',
  // },
  // '36': {
  //   title: 'Orchestral Strings',
  //   isPremium: false,
  //   folder: 'orchestral_strings',
  // },
  // '37': {
  //   title: 'Pad Downer',
  //   isPremium: false,
  //   folder: 'pad_downer',
  // },
  // '38': {
  //   title: 'Rock Guitar',
  //   isPremium: false,
  //   folder: 'rock_guitar',
  // },
}

Object.keys(SynthInstruments).forEach(key => {
  SynthInstruments[key].id = key
})

export const DrumsInstruments = {
  '0': {
    title: '#ChordChord Basic',
    isPremium: false,
    folder: 'basic',
  },
  '1': {
    title: 'Brooklyn',
    isPremium: false,
    folder: 'brooklyn',
  },
  '2': {
    title: 'Portland',
    isPremium: true,
    folder: 'portland',
  },
  '3': {
    title: 'Miami',
    isPremium: true,
    folder: 'miami',
  },
  '4': {
    title: 'Disco',
    isPremium: true,
    folder: 'disco',
  },
  '5': {
    title: 'Trap',
    isPremium: true,
    folder: 'trap',
  },
  '6': {
    title: 'Brazilian',
    isPremium: false,
    folder: 'brazilian_drums',
  },
  '7': {
    title: 'Classic',
    isPremium: false,
    folder: 'classic_drums',
  },
  '8': {
    title: 'Dirty',
    isPremium: true,
    folder: 'dirty_drums',
  },
  '9': {
    title: 'European',
    isPremium: true,
    folder: 'european_drums',
  },
  '10': {
    title: 'Future Bass',
    isPremium: false,
    folder: 'future_bass_drums',
  },
  '11': {
    title: 'Hard',
    isPremium: true,
    folder: 'hard_drums',
  },
  '12': {
    title: 'Modern',
    isPremium: true,
    folder: 'modern_drums',
  },
  '13': {
    title: 'Ocean',
    isPremium: false,
    folder: 'ocean_drums',
  },
  '14': {
    title: 'Posty',
    isPremium: true,
    folder: 'posty_drums',
  },
  '15': {
    title: 'Trance',
    isPremium: true,
    folder: 'trance_drums',
  },
}

Object.keys(DrumsInstruments).forEach(key => {
  DrumsInstruments[key].id = key
})