/* eslint-disable react/prop-types */
import React from 'react'
class SweetRoundButton extends React.PureComponent {
  static defaultProps = {
    className: '',
    color: '',
    neoSize: false,
  }

  icons = {
    play: 'icon-play icon',
    pause: 'icon-pause icon',
    random: 'icon-shuffle icon',
    settings: 'icon-settings icon',
    midi: 'icon-midi icon',
    cloudSave: 'icon-cloud-save icon',
    share: 'icon-share icon',
    lock: 'icon-lock icon',
    edit: 'icon-edit icon',
    times: 'icon-times icon',
    music: 'icon-music icon',
    stop: 'icon-stop icon',
    record: 'icon-record icon',
  }

  render() {
    let icon = ''
    if (this.props.icon in this.icons) {
      icon = this.icons[this.props.icon]
    }

    const classes = ['sweet-round-button', this.props.color, this.props.className]
    this.props.size && classes.push(this.props.size)
    this.props.hiddenOnMobile && classes.push('hidden-on-mobile')
    this.props.neoSize && classes.push('neo-size')
    const classesString = classes.join(' ')

    let textClasses = ['name']
    this.props.smallFont && textClasses.push('small-font')
    const textClassesString = textClasses.join(' ')

    if (this.props.invisible) {
      return <React.Fragment></React.Fragment>
    }

    return (
      <div
        onClick={this.props.onClick}
        className={classesString}>
        <i className={icon}></i>
        <div className={textClassesString}>{this.props.name}</div>
      </div >
    )
  }
}

export default SweetRoundButton